<template>
  <div>
    <PersonItem v-if="single" :value="value[0]"></PersonItem>
    <v-list-group v-else :value="false">
      <template v-slot:activator>
        <ListIcon><v-icon>mdi-account-circle</v-icon></ListIcon>
        <v-list-item-content>
          <v-list-item-title>{{ codes }}</v-list-item-title>
        </v-list-item-content>
      </template>
      <PersonItem
        v-for="item in value"
        :key="item.id"
        :value="item"
      ></PersonItem>
    </v-list-group>
  </div>
</template>
<script>
import PersonItem from "@/components/PersonItem";

export default {
  props: {
    value: null,
  },
  components: {
    PersonItem,
  },
  computed: {
    codes() {
      return this.value.map((item) => item.code).join(", ");
    },
    single() {
      return this.value && this.value.length === 1;
    },
  },
};
</script>
