<template>
  <div>
    <v-tabs
      v-if="type == 'day'"
      show-arrows
      centered
      center-active
      :value="currentDay - 1"
    >
      <v-tab
        v-for="day in weekdays"
        :value="currentDay"
        :key="day"
        @click="currentDay = day"
        >{{ dayOfWeek[day] }}</v-tab
      >
    </v-tabs>
    <v-calendar
      @click:event="showLesson"
      ref="calendar"
      :loading="loading"
      :start="start"
      first-time="07:00"
      interval-count="12"
      :weekdays="weekdays"
      locale="de-CH"
      :type="type"
      :events="events"
      :value="focus"
      :event-text-color="(el) => (el.dark ? 'white' : 'dark')"
    >
      <template v-slot:day-label-header="">&nbsp;</template>
    </v-calendar>
    <v-bottom-sheet
      scrollable
      min-width="350px"
      max-width="500px"
      v-model="selectedOpen"
      :close-on-content-click="false"
    >
      <v-card tile>
        <v-system-bar window
          >Lektion<v-spacer></v-spacer
          ><v-btn small icon @click="selectedOpen = false"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-system-bar>
        <v-card-text class="display text-center mt-4">
          <v-avatar :color="selectedEvent.color">
            <v-icon :color="selectedEvent.dark ? 'white' : 'black'"
              >mdi-timetable</v-icon
            >
          </v-avatar>
        </v-card-text>

        <TimetableLessonDetail :value="selectedEvent.lesson" />

        <v-divider />
        <v-card-actions>
          <v-btn block text color="primary" @click="selectedOpen = false">
            Schliessen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>
<script>
import TimetableLessonDetail from "@/components/TimetableLessonDetail";
import { addDays } from "common/utils/date";

export default {
  name: "Timetable",
  components: {
    TimetableLessonDetail,
  },
  props: {
    events: [],
    loading: Boolean,
  },
  data() {
    return {
      currentDay: 1,
      dayOfWeek: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
      selectedElement: null,
      selectedEvent: {},
      selectedOpen: false,
    };
  },
  computed: {
    focus() {
      return addDays("2022-03-21", this.currentDay - 1);
    },
    start() {
      return "2022-03-21";
    },
    weekdays() {
      if (this.events.some((event) => event.start.getDay() === 6)) {
        return [1, 2, 3, 4, 5, 6];
      }
      return [1, 2, 3, 4, 5];
    },
    type() {
      return this.$vuetify.breakpoint.xsOnly ? "day" : "week";
    },
  },
  watch: {
    events() {
      this.selectedOpen = false;
    },
  },
  methods: {
    showLesson({ event }) {
      this.selectedEvent = event;
      this.selectedOpen = true;
    },
  },
};
</script>
<style scoped>
:deep(div.v-event-timed.dark--text) {
  border-color: white !important;
}
</style>
